import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { TestMilkingFragmentDoc } from './testMilking.graphql';
export type TestMilkingUploadFragment = { __typename: 'TestMilkingUpload', id: string, happenedAt: string, cowsCount: number, hasUploadIssues: boolean, testMilkings: Array<{ __typename: 'TestMilking', id: string, happenedAt: string, daysInMilk: number, weightKilograms: number, fatPercent?: number | null, proteinPercent?: number | null, sccThousandsPerMl?: number | null, ureaMgPerDl?: number | null, penGroup?: { __typename: 'PenGroup', id: string, name: string, identifier: number } | null, cow: { __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string } }> };

export const TestMilkingUploadFragmentDoc = gql`
    fragment TestMilkingUpload on TestMilkingUpload {
  __typename
  id
  happenedAt
  cowsCount
  hasUploadIssues
  testMilkings {
    ...TestMilking
  }
}
    ${TestMilkingFragmentDoc}`;