import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
export type GenealogyCowFragment = { __typename: 'GenealogyCow', relationKind: Types.GenealogyRelationKind, registrationNumber: string, name: string, breed: string, dateOfBirth?: string | null, weightKilograms?: number | null, overallMilkWeightKilograms?: number | null, overallMilkFatPercent?: number | null, overallMilkProteinPercent?: number | null, bestLactationNumber?: number | null, bestLactationMilkWeightKilograms?: number | null, bestLactationMilkFatPercent?: number | null, bestLactationMilkProteinPercent?: number | null };

export const GenealogyCowFragmentDoc = gql`
    fragment GenealogyCow on GenealogyCow {
  __typename
  relationKind
  registrationNumber
  name
  breed
  dateOfBirth
  weightKilograms
  overallMilkWeightKilograms
  overallMilkFatPercent
  overallMilkProteinPercent
  bestLactationNumber
  bestLactationMilkWeightKilograms
  bestLactationMilkFatPercent
  bestLactationMilkProteinPercent
}
    `;